import { Box } from "@mui/material";

export default function NodeIcon({ children, index, isError }) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: index === 0 ? 50 : 45,
          height: index === 0 ? 50 : 45,
          border: "2px solid",
          borderColor:
            index === 0 && isError
              ? "#F92934"
              : index === 0 && !isError
              ? "success.main"
              : "secondary.main",
          borderRadius: "50%",
          ml: index !== 0 && "2.5px",
        }}
      >
        {children}
      </Box>
    </>
  );
}
