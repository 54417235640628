import { Box, Typography } from "@mui/material";

export default function StatusWrapper({ status, isFailed, noTracking }) {
  return (
    <>
      <Box
        sx={{
          width: "fit-content",
          height: "24px",
          borderRadius: "12px 10px",
          bgcolor: noTracking ? "#E0E0E0" : isFailed ? "#FDD4D6" : "#D8E8FF",
          p: "9px",
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          variant="overline"
          color={noTracking ? "#666666" : isFailed ? "red" : "blue"}
        >
          {status}
        </Typography>
      </Box>
    </>
  );
}
