import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { environment } from "../../app/config/environment/environment";

export const serviceApi = createApi({
  reducerPath: "serviceApi",
  keepUnusedDataFor: 60,
  baseQuery: fetchBaseQuery({
    baseUrl: environment.BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/x-www-form-urlencoded");
    },
    retry: 0,
  }),
  endpoints: (builder) => ({
    sendLogisticsQuery: builder.query({
      query: (payload) => ({
        url: "/gateway/link.do",
        method: "POST",
        body: new URLSearchParams(payload),
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useSendLogisticsQueryQuery } = serviceApi;
