import { Box } from "@mui/material";

export default function Card({ width, height, color, children }) {
  return (
    <Box
      sx={{
        width,
        height,
        borderRadius: "36px 16px 36px 16px",
        position: "relative",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        padding: "50px 0 60px 64px",
      }}
      bgcolor={color}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </Box>
  );
}
