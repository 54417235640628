export default function PUDOComponent(props) {
  return (
    <div>
      <svg
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={props.width || "20"}
        height={props.height || "20"}
        {...props}
      >
        <path
          d="M388.568615 732.16c0-9.216 7.483077-16.699077 16.699077-16.699077h215.11877c9.216 0 16.699077 7.483077 16.699076 16.699077v25.009231c0 9.216-7.483077 16.659692-16.699076 16.659692H405.267692a16.659692 16.659692 0 0 1-16.699077-16.659692v-25.009231z m0-497.033846c0-9.176615 7.483077-16.659692 16.699077-16.659692h215.11877c9.216 0 16.699077 7.483077 16.699076 16.699076v403.574154c0 9.216-7.483077 16.699077-16.699076 16.699077H405.267692a16.659692 16.659692 0 0 1-16.699077-16.699077V235.165538zM322.638769 1024h376.241231V0H322.638769v1024zM981.346462 0h-239.852308v298.653538H1024v-256C1024 19.180308 1004.819692 0 981.346462 0z m-239.852308 1024h239.852308c23.473231 0 42.653538-19.180308 42.653538-42.653538V683.716923h-282.466462V1024z m0-382.936615H1024v-299.716923h-282.466462v299.716923zM0 981.346462C0 1004.819692 19.219692 1024 42.653538 1024H280.024615v-341.346462H0v298.692924z m0-341.346462h279.985231V341.346462H0v298.653538zM0 42.653538v256h279.985231V0H42.653538C19.219692 0 0 19.180308 0 42.653538z"
          fill={props.fill || "#0066FF"}
        ></path>
      </svg>
    </div>
  );
}
