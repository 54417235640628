import React, { useState } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export default function Tracking({
  isCustomer,
  inputStyle,
  iconStyle,
  isCustomerXs,
}) {
  const [t, i18n] = useTranslation("global");
  const [search, setSearch] = useState("");

  const url = `/tracking/${search}`;
  // const url = `https://play.ecoscooting.com/tracking/${search}`;

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="input-search">
      <Input
        placeholder={t("Tracking.placeholder")}
        allowClear
        onChange={handleChange}
        style={{
          width: isCustomer ? "50%" : isCustomerXs ? "40%" : "80%",
          ...inputStyle,
        }}
      />
      <a
        className="button-search"
        href={url}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: isCustomer ? "48px" : "33px",
          height: isCustomer ? "48px" : "33px",
          backgroundColor: "#007bff",
          borderRadius: "4px",
          ...iconStyle,
        }}
      >
        <SearchOutlined style={{ fontSize: "20px", color: "white" }} />
      </a>
    </div>
  );
}
