import { TimelineContent } from "@mui/lab";
import { Grid, Stack, Typography } from "@mui/material";
import PUDOComponent from "../PUDOComponent";

export default function StatusDesc({
  item,
  isFirst,
  isError,
  popStationInfo,
  index,
}) {
  let officeTime = popStationInfo?.officeTime;
  let formattedOfficeTime;
  if (officeTime) {
    formattedOfficeTime = officeTime.split(")");
    formattedOfficeTime.pop();
  }
  return (
    <>
      <TimelineContent>
        <Stack gap={0.5} maxWidth="400px">
          <Typography
            variant={isFirst ? "h5" : "subtitle1"}
            fontWeight={isFirst && "bold"}
            color={
              isFirst && isError ? "red" : isFirst ? "primary" : "grey.500"
            }
          >
            {item.statusName}
          </Typography>
          <Typography
            variant={isFirst ? "subtitle1" : "caption"}
            color={isFirst ? "#000000" : "disabled"}
          >
            {item.description}
          </Typography>
          {popStationInfo && index === 0 && (
            <Grid
              container
              direction="row"
              bgcolor="#F6F9FE"
              p="20px"
              borderRadius="4px"
            >
              <Grid
                item
                xs={1}
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                mr="11px"
              >
                <PUDOComponent />
              </Grid>
              <Grid item xs={10}>
                <Stack gap={1}>
                  <Typography variant="body2">
                    {popStationInfo.stationName}
                  </Typography>
                  <Typography
                    variant="overline"
                    color="#333333"
                    pb="5px"
                    borderBottom="1px #B7C7D7 dashed"
                  >
                    {popStationInfo.detailAddress}
                  </Typography>
                  {/* <Typography variant="caption" color="black">
                    Tel: {popStationInfo.phone}
                  </Typography> */}
                  <Stack>
                    {formattedOfficeTime.map((item) => {
                      return (
                        <Typography variant="caption" color="black" key={item}>
                          {item + ")"}
                        </Typography>
                      );
                    })}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          )}
        </Stack>
      </TimelineContent>
    </>
  );
}
