import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineOppositeContent,
  TimelineConnector,
} from "@mui/lab";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import NodeIcon from "../NodeIcon";
import StatusDesc from "../StatusDesc";

const getStatusIcon = (item, index) => {
  const baseStyle = {
    width: index === 0 ? 30 : 20,
    height: index === 0 ? 30 : 20,
  };

  switch (item.status) {
    case "error":
      return (
        <CancelOutlinedIcon
          sx={baseStyle}
          color={index === 0 ? "red" : "secondary"}
        />
      );
    default:
      if (index === 0) {
        return <LocationOnOutlinedIcon sx={baseStyle} color="primary" />;
      }
      if (item.statusName === "Aceptado por la última milla") {
        return <WarehouseOutlinedIcon sx={baseStyle} color="secondary" />;
      }
      return <Inventory2OutlinedIcon sx={baseStyle} color="secondary" />;
  }
};

export default function TimelineComponent({ data }) {
  return (
    <Timeline sx={{ width: "100%" }}>
      {data?.statuses?.map((item, index) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent
            variant="caption"
            color={index === 0 ? "#000000" : "grey.500"}
            sx={{ flex: "0.5", mt: "10px", maxWidth: "120px" }}
          >
            {item?.datetime}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <NodeIcon index={index} isError={item.status === "error"}>
              {getStatusIcon(item, index)}
            </NodeIcon>
            {index !== data?.statuses.length - 1 && (
              <TimelineConnector
                sx={{
                  height: "50px",
                  ml: index !== 0 && "2.5px",
                  backgroundColor:
                    item.status === "error" && index === 0
                      ? "red.main"
                      : index === 0
                      ? "success.main"
                      : "secondary.main",
                }}
              />
            )}
          </TimelineSeparator>
          <StatusDesc
            index={index}
            popStationInfo={data?.popStationParam}
            item={item}
            isFirst={index === 0}
            isError={item.status === "error"}
          />
        </TimelineItem>
      ))}
    </Timeline>
  );
}
