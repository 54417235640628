import { Box, Container, Typography } from "@mui/material";

export default function Banner() {
  return (
    <Box
      sx={{
        position: "relative",
        height: "338px",
        backgroundImage: `url("background.png")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        color: "white",
        mt: "140px",
        boxShadow: "0px 10px 100px #E8F4FF",
      }}
    >
      <Container
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            color: "#BFF542",
            mb: "25px",
          }}
        >
          Entregas a domicilio
        </Typography>
        <Typography variant="h5" color="white">
          Tenemos la cobertura y rapidez que tus clientes necesitan
        </Typography>
      </Container>
    </Box>
  );
}
