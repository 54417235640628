import { Box, Stack, Toolbar, Typography } from "@mui/material";
import Card from "../../../components/Card";

export default function Cards() {
  return (
    <>
      <Toolbar sx={{ height: "92px" }} />
      <Stack gap="132px" px="108px" alignItems="center" mb="96px">
        <Card width="1224px" height="286px" color="#1A8DEF">
          <Stack gap={2}>
            <Typography variant="h2" color="white" fontWeight="700">
              Soluciones RFID
            </Typography>
            <UnderLine />
          </Stack>
          <PhotoCard />
        </Card>
        <Card width="1224px" height="286px" color="#3D5366">
          <Stack gap={2}>
            <Typography variant="h2" color="white" fontWeight="700">
              Locker
            </Typography>
            <UnderLine />
          </Stack>
        </Card>
        <Card width="1224px" height="286px" color="#7EA3C2" fontWeight="700">
          <Stack gap={2}>
            <Typography variant="h2" color="white" fontWeight="700">
              Automatización
            </Typography>
            <UnderLine />
          </Stack>
        </Card>
      </Stack>
    </>
  );
}

function PhotoCard() {
  return (
    <Box
      component="img"
      src="/logo.png"
      sx={{
        width: "556px",
        height: "286px",
        position: "absolute",
        right: "36px",
        bottom: "36px",
        borderRadius: "36px 16px 36px 16px",
      }}
    />
  );
}

function UnderLine() {
  return (
    <Box bgcolor="green.main" width="120px" height="6px" borderRadius="4.5px" />
  );
}
