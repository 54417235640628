import React from "react";
import Header from "../../components/Header";
import SearchTracking from "./components/SearchTracking";
import FAQ from "./components/FAQ";
import Contact from "./components/Contact";
import ChatWithUs from "../../components/chatWithUs";

export default function CustomerServicePage() {
  return (
    <>
      <SearchTracking />
      <Header />
      <Contact />
      <FAQ />
      <ChatWithUs />
    </>
  );
}
