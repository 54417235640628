import CustomHeader from "./components/CustomHeader";
import SearchBar from "./components/SearchBar";

export default function index() {
  return (
    <>
      <SearchBar />
      <CustomHeader />
    </>
  );
}
