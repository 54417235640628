import React, { useState } from "react";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom/";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    borderColor: "#3C91FF",
    backgroundColor: "#FFFFFF",
    "& fieldset": {
      borderColor: "#3C91FF",
    },
    "&:hover fieldset": {
      borderColor: "#3C91FF",
      boxShadow: "0 0 8px rgba(60, 145, 255, 0.5)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3C91FF",
      boxShadow: "0 0 8px rgba(60, 145, 255, 0.8)",
    },
  },
  "& .MuiInputBase-input": {
    padding: "12px 16px",
    color: theme.palette.text.primary,
  },
}));

const CustomInput = () => {
  const [value, setValue] = useState("");
  const history = useHistory();
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      history.push(`/tracking/${value}`);
      window.location.reload();
    }
  };

  return (
    <StyledTextField
      variant="outlined"
      placeholder="Enter the tracking number"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon style={{ color: "#3C91FF" }} />
          </InputAdornment>
        ),
      }}
      fullWidth
      sx={{ m: "16px 0" }}
      onChange={(event) => {
        setValue(event.target.value);
      }}
      onKeyDown={handleKeyDown}
    />
  );
};

export default CustomInput;
