import { IconButton, Tooltip } from "@mui/material";
import { useState } from "react";

export default function CopyButton({ orderNumber }) {
  const [tooltipText, setTooltipText] = useState("copiar");
  const handleCopy = () => {
    navigator.clipboard
      .writeText(orderNumber)
      .then(() => {
        setTooltipText("copiado!");
        setTimeout(() => setTooltipText("copiar"), 2000);
      })
      .catch((err) => {
        setTooltipText("Error al copiar");
        setTimeout(() => setTooltipText("copiar"), 2000);
      });
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Tooltip title={tooltipText} arrow>
        <IconButton onClick={handleCopy}>
          <CopyIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}

const CopyIcon = ({
  width = "20px",
  height = "20px",
  fill = "#1B91FF",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>copy</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-346.000000, -238.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <g transform="translate(114.000000, 145.000000)">
            <g transform="translate(22.000000, 88.000000)">
              <g transform="translate(210.000000, 5.000000)">
                <rect opacity="0" x="0" y="0" width="14" height="14"></rect>
                <path d="M9.625,5.25 L9.625,12.25 L2.625,12.25 L2.625,5.25 L9.625,5.25 M10.0625,4.375 L2.1875,4.375 C1.94587542,4.375 1.75,4.57087542 1.75,4.8125 L1.75,12.6875 C1.75,12.9291246 1.94587542,13.125 2.1875,13.125 L10.0625,13.125 C10.3041246,13.125 10.5,12.9291246 10.5,12.6875 L10.5,4.8125 C10.5,4.57087542 10.3041246,4.375 10.0625,4.375 Z"></path>
                <path d="M12.6875,1.75 L4.8125,1.75 C4.57087542,1.75 4.375,1.94587542 4.375,2.1875 L4.375,3.5 L5.25,3.5 L5.25,2.625 L12.25,2.625 L12.25,10.5 L11.375,10.5 L11.375,11.375 L12.6875,11.375 C12.9291246,11.375 13.125,11.1791246 13.125,10.9375 L13.125,2.1875 C13.125,1.94587542 12.9291246,1.75 12.6875,1.75 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
