import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/";

const styleReset = {
  "& .MuiOutlinedInput-root": {
    border: "0",
    boxShadow: "none",
    outline: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
};

export default function SearchTracking() {
  const [t, i18n] = useTranslation("global");
  const [search, setSearch] = useState("");

  const history = useHistory();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      history.push(`/tracking/${search}`);
      window.location.reload();
    }
  };
  return (
    <>
      <TextField
        type="text"
        placeholder="Enter the tracking number"
        onChange={(event) => {
          setSearch(event.target.value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: "#3C91FF" }} />
            </InputAdornment>
          ),
        }}
        onKeyDown={handleKeyDown}
        sx={{ m: "16px 0", position: "fixed", top: "497px", ...styleReset }}
      ></TextField>
    </>
  );
}
