import { createSlice } from "@reduxjs/toolkit";
import i18next from "i18next";

const initialState = {
  language: "es",
};

const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    changeLan(state, action) {
      state.language = action.payload;
      i18next.changeLanguage(action.payload);
    },
  },
});

export const selectLan = (state) => state.header.language;

export const { changeLan } = headerSlice.actions;
export default headerSlice.reducer;
