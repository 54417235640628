import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export default function Footer(props) {
    const [t, i18n] = useTranslation("global");

    return (
    <>
      {/* Exterior Box */}
      <Box>
        {/* Box with black background */}
        <Box 
          sx={ {
          display:"flex",
          backgroundColor: "black",
          width: "100%",
          height: "227px",
          justifyContent: "space-between",
          padding: "5px",
        } }>
          {/* Box with logo and copyright*/}
          <Box display={"flex"} flexDirection={"column"}> 
            <Box
              component="a"
              href="/"
              sx={{
                flexGrow: 0.6,
                display: "block",
                pl: 10,
                pt: 4,
              }}
            >
              <img src="/logo.png" alt="logo" width="200px" height="50px" />
            </Box>
            <Typography variant="caption" color="white" sx={{pl: 10, opacity: 0.8, fontWeight: 100}} >
              { t("Footer.titleEcos") }, { t("Footer.allRights") }
            </Typography>
          </Box>

          {/* Boxes wrapping info boxes | ASKKKKKKKKK esto tiene que usar t()*/}
          <Box 
            display={"flex"}
            gap={7}
            sx={{
              pt: 4,
              flexGrow: 0.4,
            }}
          >
            {/* About us and Updates box */}
            <Box sx={ {flexGrow: 0.1, display: "flex", flexDirection: "column"} }>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                { t("Footer.nosotros") }
              </Typography>
              <Typography component="a" href="todo.com" variant="caption" color="white"
                          sx= {{opacity: 0.8, fontWeight: 100}}>
                <br/>
                { t("Footer.sobre-nosotros") }
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                <br/>
                { t("Footer.novedades") }
              </Typography>
              <Typography component="a" href="todo.com" variant="caption" color="white"
                          sx= {{opacity: 0.8, fontWeight: 100}}>
                <br/>
                { t("Footer.nuevas-zonas-de-reparto") }
              </Typography>
            </Box>

            {/* Services box */}
            <Box sx={ {flexGrow: 0.1, display: "flex", flexDirection: "column"} }>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                { t("Footer.servicios") }
              </Typography>
              <Typography component="a" href="todo.com" variant="caption" color="white"
                          sx= {{opacity: 0.8, fontWeight: 100}}>
                <br/>
                { t("Footer.nuestros-servicios") }
              </Typography>
              <Typography component="a" href="todo.com" variant="caption" color="white"
                          sx= {{opacity: 0.8, fontWeight: 100}}>
                <br/>
                { t("Footer.sigue-tu-pedido") }
              </Typography>
            </Box>

            {/* Contact us box */}
            <Box sx={ {flexGrow: 0.1, display: "flex", flexDirection: "column"} }>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                { t("Footer.contactanos") }
              </Typography>
              <Typography component="a" href="todo.com" variant="caption" color="white"
                          sx= {{opacity: 0.8, fontWeight: 100}}>
                <br/>
                { t("Footer.servicio-al-cliente") }
              </Typography>
              <Typography component="a" href="todo.com" variant="caption" color="white"
                          sx= {{opacity: 0.8, fontWeight: 100}}>
                <br/>
                { t("Footer.chatea-con-nosotros") }
              </Typography>
              <Typography component="a" href="todo.com" variant="caption" color="white"
                          sx= {{opacity: 1, fontWeight: 100}}>
                <br/>
                { t("Footer.preguntas-frecuentes") }
              </Typography>
            </Box>

            {/* Legal Box */}
            <Box sx={ {flexGrow: 0.1, display: "flex", flexDirection: "column"} }>
              <Typography variant="subtitle1" fontWeight="bold" color="white">
                { t("Footer.legal") }
              </Typography>
              <Typography component="a" href="/aviso-legal" variant="caption" color="white"
                          sx= {{opacity: 0.8, fontWeight: 100}}>
                <br/>
                { t("Footer.aviso-legal-url") }
              </Typography>
              <Typography component="a" href="/politica-privacidad" variant="caption" color="white"
                          sx= {{opacity: 0.8, fontWeight: 100}}>
                <br/>
                { t("Footer.politica-privacidad-url") }
              </Typography>
              <Typography component="a" href="/politica-cookies" variant="caption" color="white"
                          sx= {{opacity: 0.8, fontWeight: 100}}>
                <br/>
                { t("Footer.politica-cookies-url") }
              </Typography>
            </Box>
          </Box>
        </Box>


        {/* Bottom Border Colors */}
        <Box sx={{
          borderBottom: '7px solid #0081ED',
        }} />
        
        <Box sx={{
          borderBottom: '7px solid #84C7FF',
        }} />
      </Box>
    </>
    );
}