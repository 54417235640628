import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useBreakpoints } from "../../../../app/hooks/useBreakpoints";

export default function FAQ() {
  const [t, i18n] = useTranslation("global");
  const { lg } = useBreakpoints();
  const click = t("HotTopic.Click");
  const faqs = [
    {
      question: t("CommonQues.q1"),
      answer: t("CommonQues.a1"),
    },
    {
      question: t("CommonQues.q2"),
      answer: t("CommonQues.a2")
        .replace(
          "[website]",
          "<a href='https://www.ecoscooting.com'>https://www.ecoscooting.com</a>"
        )
        .replace(
          "[espainCustomerService]",
          `<a href="https://xl-web.sg.cainiao.com/xlPcPage.html?channel=1000000013&language=es">${click}</a>`
        )
        .replace(
          "[espainFeedbackForm]",
          `<a href=https://echo.sg.cainiao.com/p/emailTask/officialForm/create?LAYOUT=NO&lang=es-ES&id=ecoscooting>${click}</a>`
        )
        .replace(
          "[portugalCustomerService]",
          `<a href="https://xl-web.sg.cainiao.com/xlPcPage.html?channel=1000000028&language=pt-PT">${click}</a>`
        )
        .replace(
          "[portugalFeedbackForm]",
          `<a href=https://echo.sg.cainiao.com/p/emailTask/officialForm/create?LAYOUT=NO&lang=pt-ES&id=ecoscooting>${click}</a>`
        ),
    },
    {
      question: t("CommonQues.q3"),
      answer: t("CommonQues.a3"),
    },
    {
      question: t("CommonQues.q4"),
      answer: t("CommonQues.a4").replace(
        "[top]",
        `<a href='#top' />${click}</a>`
      ),
    },
    {
      question: t("CommonQues.q5"),
      answer: t("CommonQues.a5").replace(
        "[oficial]",
        `<a href='https://www.ecoscooting.com' />${click}</a>`
      ),
    },
    {
      question: t("CommonQues.q6"),
      answer: t("CommonQues.a6"),
    },
    {
      question: t("CommonQues.q7"),
      answer: t("CommonQues.a7"),
    },
    ...(i18n.language !== "pt"
      ? [
          {
            question: t("CommonQues.q8"),
            answer: t("CommonQues.a8"),
          },
        ]
      : []),
  ];

  const hotTopic = [
    {
      question: t("HotTopic.q1"),
      answer: t("HotTopic.a1"),
    },
    {
      question: t("HotTopic.q2"),
      answer: t("HotTopic.a2")
        .replace(
          "[espainCustomerService]",
          `<a href="https://xl-web.sg.cainiao.com/xlPcPage.html?channel=1000000013&language=es">${click}</a>`
        )
        .replace(
          "[espainFeedbackForm]",
          `<a href=https://echo.sg.cainiao.com/p/emailTask/officialForm/create?LAYOUT=NO&lang=es-ES&id=ecoscooting>${click}</a>`
        )
        .replace(
          "[portugalCustomerService]",
          `<a href="https://xl-web.sg.cainiao.com/xlPcPage.html?channel=1000000013&language=es">${click}</a>`
        )
        .replace(
          "[portugalFeedbackForm]",
          `<a href=https://echo.sg.cainiao.com/p/emailTask/officialForm/create?LAYOUT=NO&lang=pt-ES&id=ecoscooting>${click}</a>`
        ),
    },
    {
      question: t("HotTopic.q3"),
      answer: t("HotTopic.a3")
        .replace(
          "[espainCustomerService]",
          `<a href="https://xl-web.sg.cainiao.com/xlPcPage.html?channel=1000000013&language=es">${click}</a>`
        )
        .replace(
          "[espainFeedbackForm]",
          `<a href=https://echo.sg.cainiao.com/p/emailTask/officialForm/create?LAYOUT=NO&lang=es-ES&id=ecoscooting>${click}</a>`
        )
        .replace(
          "[portugalCustomerService]",
          `<a href="https://xl-web.sg.cainiao.com/xlPcPage.html?channel=1000000013&language=es">${click}</a>`
        )
        .replace(
          "[portugalFeedbackForm]",
          `<a href=https://echo.sg.cainiao.com/p/emailTask/officialForm/create?LAYOUT=NO&lang=pt-ES&id=ecoscooting>${click}</a>`
        ),
    },
    {
      question: t("HotTopic.q4"),
      answer: t("HotTopic.a4").replace(
        "[top]",
        `<a href='#top' />${click}</a>`
      ),
    },
    {
      question: t("HotTopic.q5"),
      answer: t("HotTopic.a5").replace(
        "[oficial]",
        `<a href='https://www.ecoscooting.com' />${click}</a>`
      ),
    },
    {
      question: t("HotTopic.q6"),
      answer: t("HotTopic.a6")
        .replace(
          "[website]",
          "<a href='https://www.ecoscooting.com'>https://www.ecoscooting.com</a>"
        )
        .replace(
          "[espainCustomerService]",
          `<a href="https://xl-web.sg.cainiao.com/xlPcPage.html?channel=1000000013&language=es">${click}</a>`
        )
        .replace(
          "[espainFeedbackForm]",
          `<a href=https://echo.sg.cainiao.com/p/emailTask/officialForm/create?LAYOUT=NO&lang=es-ES&id=ecoscooting>${click}</a>`
        )
        .replace(
          "[portugalCustomerService]",
          `<a href="https://xl-web.sg.cainiao.com/xlPcPage.html?channel=1000000013&language=es">${click}</a>`
        )
        .replace(
          "[portugalFeedbackForm]",
          `<a href=https://echo.sg.cainiao.com/p/emailTask/officialForm/create?LAYOUT=NO&lang=pt-ES&id=ecoscooting>${click}</a>`
        ),
    },
    ...(i18n.language !== "pt"
      ? [
          {
            question: t("HotTopic.q7"),
            answer: t("HotTopic.a7")
              .replace(
                "[website]",
                "<a href='https://www.ecoscooting.com'>https://www.ecoscooting.com</a>"
              )
              .replace(
                "[espainCustomerService]",
                `<a href="https://xl-web.sg.cainiao.com/xlPcPage.html?channel=1000000013&language=es">${click}</a>`
              )
              .replace(
                "[espainFeedbackForm]",
                `<a href=https://echo.sg.cainiao.com/p/emailTask/officialForm/create?LAYOUT=NO&lang=es-ES&id=ecoscooting>${click}</a>`
              )
              .replace(
                "[portugalCustomerService]",
                `<a href="https://xl-web.sg.cainiao.com/xlPcPage.html?channel=1000000013&language=es">${click}</a>`
              )
              .replace(
                "[portugalFeedbackForm]",
                `<a href=https://echo.sg.cainiao.com/p/emailTask/officialForm/create?LAYOUT=NO&lang=pt-ES&id=ecoscooting>${click}</a>`
              ),
          },
        ]
      : []),

    ...(i18n.language !== "pt"
      ? [
          {
            question: t("HotTopic.q8"),
            answer: t("HotTopic.a8"),
          },
        ]
      : []),
    ...(i18n.language !== "pt"
      ? [
          {
            question: t("HotTopic.q9"),
            answer: t("HotTopic.a9"),
          },
        ]
      : []),
  ];
  const imageMap = {
    es: "faq_es.png",
    en: "faq_en.png",
    pt: "faq_pt.png",
  };

  return (
    <Box sx={{ height: "100vh" }}>
      <Box component="img" src={imageMap[i18n.language] || ""} width="100%" />
      <Grid
        container
        spacing={5}
        sx={{ p: 2 }}
        direction={lg ? "row" : "column"}
      >
        <Grid item lg={6}>
          <Stack gap={2}>
            <Box>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                {t("Customer.hotTopic")}
              </Typography>
            </Box>
            <Box>
              {hotTopic.map((faq, index) => (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      {faq.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="subtitle2"
                      component="div"
                      dangerouslySetInnerHTML={{
                        __html: faq.answer.replace(/\n/g, "<br>"),
                      }}
                    />
                    {/* {index === 2 && (
                      <Box
                        component="img"
                        src="contactItems/ans3_es.png"
                        width="30em"
                      />
                    )}
                    {index === 4 && (
                      <Box
                        component="img"
                        src="contactItems/oficial.png"
                        width="30em"
                      />
                    )} */}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </Stack>
        </Grid>
        <Grid item lg={6}>
          <Stack gap={2}>
            <Box>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                {t("Customer.frequentQues")}
              </Typography>
            </Box>
            <Box>
              {faqs.map((faq, index) => (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      {faq.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* Render HTML answer using dangerouslySetInnerHTML */}
                    <Typography
                      variant="subtitle2"
                      component="div"
                      dangerouslySetInnerHTML={{
                        __html: faq.answer.replace(/\n/g, "<br>"),
                      }}
                    />
                    {index === 2 && i18n.language !== "en" && (
                      <Box
                        component="img"
                        src={
                          i18n.language === "es"
                            ? "contactItems/ans3_es.png"
                            : "contactItems/ans3_pt.png"
                        }
                        width="30em"
                      />
                    )}
                    {index === 4 && (
                      <Box
                        component="img"
                        src="contactItems/oficial.png"
                        width="30em"
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
