import { Box, Typography } from "@mui/material";
import SearchTracking from "./SearchTracking";

export default function SearchBar() {
  return (
    <>
      <Box
        component="img"
        src="/searchBg.png"
        sx={{
          zIndex: 1101,
          position: "fixed",
          minHeight: "130px",
          maxHeight: "130px",
          top: "497px",
          width: "100vw",
        }}
      />
      <SearchTracking />
    </>
  );
}
