import { Box, Button, Stack, Typography } from "@mui/material";

export default function ContactSection() {
  return (
    <Stack height={300} alignItems="center" gap="60px">
      <Typography variant="h2">Te interesa saber mas</Typography>
      <Button
        disableRipple
        disableFocusRipple
        sx={{
          position: "relative",
          "&:hover": {
            background: "none",
          },
        }}
      >
        <Box component="img" src="/greenButtonNormal.png" width={347}></Box>
        <Typography
          variant="h4"
          position="absolute"
          color="white"
          fontWeight="bold"
          sx={{ textDecoration: "none", left: "80px" }}
        >
          Te Contactamos
        </Typography>
      </Button>
    </Stack>
  );
}
