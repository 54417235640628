import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import QuestionComponent from "../QuestionComponent";

export default function ChatBotWidget() {
  const [t, i18n] = useTranslation("global");

  return (
    <Box
      component="a"
      href={`https://echo.sg.cainiao.com/p/emailTask/officialForm/create?LAYOUT=NO&lang=${i18n.language}-ES&id=ecoscooting`}
      sx={{
        position: "fixed",
        bottom: 300,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        borderRadius: "40px 0 0 40px",
        boxShadow: 3,
        backgroundColor: "white",
        width: "50px",
        height: "50px",
        overflow: "hidden",
        transition: "width 0.3s ease",
        cursor: "pointer",
        "&:hover": {
          width: "150px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          mt: 1,
          flexShrink: 0,
        }}
      >
        <QuestionComponent />
      </Box>

      <Typography
        variant="subtitle1"
        sx={{
          whiteSpace: "nowrap",
          color: "#007BFC",
          fontWeight: "bold",
          opacity: 0,
          transition: "opacity 0.3s ease",
          ml: -0.5,
          "&:hover": {
            opacity: 1,
          },
        }}
      >
        {t("Tracking.chat")}
      </Typography>
    </Box>
  );
}
