import { configureStore } from "@reduxjs/toolkit";
import { serviceApi } from "../../features/Tracking/serviceSlice";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import headerReducer from "../../Home/headerSlice";

const persistConfig = {
  key: "header",
  storage,
};

const persistedReducer = persistReducer(persistConfig, headerReducer);

export const store = configureStore({
  reducer: {
    [serviceApi.reducerPath]: serviceApi.reducer,
    header: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(serviceApi.middleware),
});

export const persistor = persistStore(store);
