import ChatBotWidget from "../../components/chatBotWidget";
import Header from "../../components/Header";
import Main from "./main";

export default function Tracking() {
  return (
    <>
      <Header />
      <Main />
      <ChatBotWidget />
    </>
  );
}
