import { Box, Stack, Typography } from "@mui/material";

export default function Cards() {
  return (
    <>
      <Stack pt={11} pb={10}>
        <Box display={"flex"} gap={4} justifyContent={"center"}>
          <CustomBox width={"388px"} height={"320px"}>
            <CustomBoxImg src={"/toDoor/Truck.png"} />
            <CustomTypo>
              Transporte Nacional <br />
              En 24-48 horas
            </CustomTypo>
          </CustomBox>

          <CustomBox width={"388px"} height={"320px"}>
            <CustomBoxImg src={"/toDoor/DeliverMan.png"} />
            <CustomTypo>
              Entrega en fin <br />
              de semana
            </CustomTypo>
          </CustomBox>

          <CustomBox width={"388px"} height={"320px"}>
            <CustomBoxImg src={"/toDoor/Email.png"} />
            <CustomTypo>
              Notificaciones vía <br />
              email para tu cliente
            </CustomTypo>
          </CustomBox>
        </Box>

        <Box display={"flex"} pt={4} gap={4} justifyContent={"center"}>
          <CustomBox width={"598px"} height={"320px"}>
            <CustomBoxImg src={"/toDoor/Trolley.png"} />
            <CustomTypo>
              Cliente tiene 2 intentos para <br />
              recibir su envío
            </CustomTypo>
          </CustomBox>
          <CustomBox width={"598px"} height={"320px"}>
            <CustomBoxImg src={"/toDoor/Shop.png"} />
            <Stack>
              <Typography
                sx={{ color: "#808080", textAlign: "center", fontSize: "14px" }}
              >
                *Válido solo para España
              </Typography>
              <CustomTypo>
                El 3er intento se entrega en un <br />
                Punto de Recogida*
              </CustomTypo>
            </Stack>
          </CustomBox>
        </Box>
      </Stack>

      <Stack pb={6} sx={{ backgroundColor: "#F7F7F7" }}>
        <CustomTypoBold pt={8}>
          Entregas sostenibles en la última milla
        </CustomTypoBold>

        <Box
          display={"flex"}
          pt={14}
          pb={2}
          sx={{ alignContent: "center", justifyContent: "center" }}
        >
          <Stack pr={6} pt={2}>
            <CustomTypoBold color="#0081ED" fontSize="5rem">
              20%
            </CustomTypoBold>
            <CustomTypoBold pt={4}>
              de nuestra ruta es
              <br />
              sostenible
            </CustomTypoBold>
          </Stack>

          <Box width={"4px"} height={"144px"} backgroundColor={"#0081ED"} />

          <Typography
            pl={4}
            pt={5}
            fontSize={"1.25rem"}
            color="#7B7B7B"
            variant="caption"
            fontWeight={100}
          >
            Medios
            <br />
            de reparto
          </Typography>

          <Box pl={6} display={"flex"} gap={8}>
            <Stack pr={2}>
              <CustomBoxCircular
                src={"/toDoor/Vehiculos electricos.svg"}
              ></CustomBoxCircular>
              <TypoUnderIcons>
                Vehículos
                <br />
                eléctricos
              </TypoUnderIcons>
            </Stack>

            <Stack>
              <CustomBoxCircular
                src={"/toDoor/Scooter.svg"}
              ></CustomBoxCircular>
              <TypoUnderIcons>
                Bicicletas y<br />
                scooters eléctricos
              </TypoUnderIcons>
            </Stack>

            <Stack>
              <CustomBoxCircular
                src={"/toDoor/Caminando.svg"}
              ></CustomBoxCircular>
              <TypoUnderIcons pt={4.5}>Caminando</TypoUnderIcons>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </>
  );
}

const CustomBox = ({ children, width, height }) => {
  return (
    <Box
      bgcolor="#CDE8FF"
      display="flex"
      flexDirection={"column"}
      justifyContent="center"
      alignItems="center"
      borderRadius={"36px 16px 36px 16px"}
      width={{ width }}
      height={{ height }}
    >
      {children}
    </Box>
  );
};

const CustomTypo = ({ children }) => {
  return (
    <Typography
      sx={{
        fontSize: "24px",
        color: "#000000",
        letterSpacing: "0",
        textAlign: "center",
        lineHeight: "33px",
        fontWeight: 700,
      }}
    >
      {children}
    </Typography>
  );
};

const CustomBoxImg = ({ src }) => {
  return (
    <Box
      component="img"
      src={src}
      alt="Imagen en Box"
      display={"flex"}
      sx={{
        width: "140px",
        height: "140px",
        objectFit: "cover",
      }}
    />
  );
};

const CustomTypoBold = ({ children, ...props }) => {
  return (
    <Typography
      variant="h4"
      fontWeight="bold"
      fontSize="2rem"
      textAlign={"center"}
      {...props}
    >
      {children}
    </Typography>
  );
};

const CustomBoxCircular = ({ src }) => {
  return (
    <Box
      component="img"
      src={src}
      sx={{
        width: "136px",
        height: "136px",
        borderRadius: "50%",
        backgroundColor: "white",
        padding: 4,
        objectFit: "cover",
      }}
    />
  );
};

const TypoUnderIcons = ({ children, ...props }) => {
  return (
    <Typography
      pt={3}
      textAlign={"center"}
      variant="caption"
      fontWeight={400}
      fontSize={"1.25rem"}
      {...props}
    >
      {children}
    </Typography>
  );
};
