import { createTheme } from "@mui/material";
import { FontFamilies } from "./FontFamilies";
import { FontWeights } from "./FontWeights";
import { THEME_COLOR } from "../../constants/constants";
const theme = createTheme({
  palette: {
    primary: {
      main: THEME_COLOR,
    },
    secondary: {
      main: "#90C9FF",
    },
    success: {
      main: "#2194FF",
    },
    grey: {
      [100]: "#E8E8E8",
      [200]: "#F2F2F2",
      [300]: "#E0E0E0",
      [400]: "#666666",
      [500]: "#858585",
    },
    blue: {
      main: "#1B91FF",
    },
    red: {
      main: "#F92934",
    },
    disabled: {
      main: "#AEAEAE",
    },
    green: {
      main: "#B2E959",
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
  },
});
theme.typography.h1 = {
  fontFamily: FontFamilies.sansSerif,
  fontWeight: FontWeights.medium,
  fontSize: "2.1rem",
  lineHeight: "3rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.5rem",
    lineHeight: "2.25rem",
  },
};
theme.typography.h2 = {
  fontFamily: FontFamilies.sansSerif,
  fontWeight: FontWeights.medium,
  fontSize: "1.875rem",
  lineHeight: "2.813rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.375rem",
    lineHeight: "2.063rem",
  },
};
theme.typography.h4 = {
  fontFamily: FontFamilies.sansSerif,
  fontWeight: FontWeights.medium,
  fontSize: "1.5rem",
  lineHeight: "2.25rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.25rem",
    lineHeight: "1.875rem",
  },
};
theme.typography.h5 = {
  fontFamily: FontFamilies.sansSerif,
  fontWeight: FontWeights.medium,
  fontSize: "1.375rem",
  lineHeight: "2.063rem",
};
theme.typography.h6 = {
  fontFamily: FontFamilies.sansSerif,
  fontWeight: FontWeights.medium,
  fontSize: "1.25rem",
  lineHeight: "1.875rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.125rem",
    lineHeight: "1.688rem",
  },
};
theme.typography.body1 = {
  fontFamily: FontFamilies.sansSerif,
  fontWeight: FontWeights.regular,
  fontSize: "1.125rem",
  lineHeight: "1.688rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
};
theme.typography.body2 = {
  fontFamily: FontFamilies.sansSerif,
  fontWeight: FontWeights.medium,
  fontSize: "1.125rem",
  lineHeight: "1.688rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
};
theme.typography.subtitle1 = {
  fontFamily: FontFamilies.sansSerif,
  fontWeight: FontWeights.regular,
  fontSize: "1rem",
  lineHeight: "1.5rem",
};
theme.typography.subtitle2 = {
  fontFamily: FontFamilies.sansSerif,
  fontWeight: FontWeights.medium,
  fontSize: "1rem",
  lineHeight: "1.5rem",
};
theme.typography.caption = {
  fontFamily: FontFamilies.sansSerif,
  fontWeight: FontWeights.regular,
  fontSize: "0.875rem",
  lineHeight: "1.313rem",
};
theme.typography.overline = {
  fontFamily: FontFamilies.sansSerif,
  fontWeight: FontWeights.semibold,
  fontSize: "0.75rem",
  lineHeight: "1.313rem",
};
export default theme;
