import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changeLan } from "../Home/headerSlice";
export default function Language() {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  return (
    <div className="select-languaje">
      <img
        src="https://img.icons8.com/office/30/000000/portugal.png"
        onClick={() => {
          dispatch(changeLan("pt"));
          i18n.changeLanguage("pt");
        }}
        alt="portuguese"
      />
      <img
        src="https://img.icons8.com/office/30/000000/usa.png"
        onClick={() => {
          dispatch(changeLan("en"));
          i18n.changeLanguage("en");
        }}
        alt="english"
      />
      <img
        src="https://img.icons8.com/office/30/000000/spain-2.png"
        onClick={() => {
          dispatch(changeLan("es"));
          i18n.changeLanguage("es");
        }}
        alt="español"
      />
    </div>
  );
}
