import React, { useState, useEffect } from "react";
import { enquireScreen } from "enquire-js";
import Header from "./Header";
import Banner from "./Banner";
import Services from "./Services";
import Hubs from "./Hubs";
import Fleet from "./Fleet";
import Platform from "./Platform";
import Footer from "./Footer";
import Chatbot from "./Chatbot";
import { useTranslation, withTranslation } from "react-i18next";
import ChatBotWidget from "../components/chatBotWidget";
import ChatWithUs from "../components/chatWithUs";

const Home = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isFirstScreen, setIsFirstScreen] = useState(true);
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    enquireScreen((b) => setIsMobile(!!b));

    const enquireHandler = (b) => setIsMobile(!!b);
    enquireScreen(enquireHandler);

    return () => {
      enquireScreen(null);
    };
  }, []);

  const onEnterChange = (mode) => {
    setIsFirstScreen(mode === "enter");
  };

  const Menu = withTranslation()(Header);

  return (
    <>
      <Menu
        key="header"
        isFirstScreen={isFirstScreen}
        isMobile={isMobile}
        t={t}
        i18n={i18n}
      />
      <Banner key="banner" onEnterChange={onEnterChange} />
      <Services key="Services" isMobile={isMobile} />
      <Hubs key="Hubs" isMobile={isMobile} />
      {/* <Fleet key="Fleet" isMobile={isMobile} /> */}
      {/* <Platform key="Platform" isMobile={isMobile} /> */}
      <Footer key="footer" />
      {/* <Chatbot key="chatbot" isMobile={isMobile} /> */}
      <ChatWithUs />
    </>
  );
};

export default Home;
