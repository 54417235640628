import { Box, Grid, Stack, Toolbar, Typography } from "@mui/material";
import { useSendLogisticsQueryQuery } from "../serviceSlice";
import CustomInput from "../../../components/CustomInput";
import StatusWrapper from "../components/StatusWrapper";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CopyButton from "../components/CopyButton";
import TimelineComponent from "../components/Timeline";
import CaptionWrapper from "../components/CaptionWrapper";

export default function Main() {
  let { id } = useParams();
  const [t, i18n] = useTranslation("global");

  const getLan = (item) => {
    switch (item) {
      case "en":
        return "en_US";
      case "es":
        return "es_ES";
      case "pt":
        return "pt_PT";
      default:
        break;
    }
  };

  if (id.startsWith("ICP") && !id.endsWith("001")) {
    id += "001";
  }

  const { data, isError, isSuccess, isLoading, error } =
    useSendLogisticsQueryQuery({
      logistics_interface: JSON.stringify({
        mailNo: id,
        locale: getLan(i18n.language),
        role: "endUser",
      }),
      msg_type: "CN_OVERSEA_LOGISTICS_INQUIRY_TRACKING",
      logistic_provider_id: "DISTRIBUTOR_30250031",
      data_digest: "suibianxie",
      to_code: "CNL_EU",
    });

  let miraId;
  if (data?.feature) miraId = JSON.parse(data?.feature).tradeOrderId;

  let isStatusError =
    data?.statuses &&
    data?.statuses.length > 0 &&
    data.statuses[0].status === "error";

  let noTracking = data?.statuses?.length === 0;

  const isMiraOrAE = (data) => {
    return (
      ["MIRAVIA", "AE"].includes(data?.solutionParam?.bizSource) &&
      ["L2L", "L2L-CR"].includes(data?.solutionParam?.logisticsMode)
    );
  };

  const isMiraOrAECB = (data) => {
    return (
      ["MIRAVIA", "AE"].includes(data?.solutionParam?.bizSource) &&
      ["CB"].includes(data?.solutionParam?.logisticsMode)
    );
  };

  const showWeight = () => {
    return (
      ["L2L"].includes(data?.solutionParam?.logisticsMode) &&
      data?.solutionParam?.bizSource === "MIRAVIA"
    );
  };

  let isPudoOverdue =
    data?.statuses &&
    data?.statuses.length > 0 &&
    data?.statuses?.[0].actionCode === "GTMS_PUDO_OVERDUE";

  let outOrderId;
  if (data?.packageParam?.outOrderId.includes("_")) {
    outOrderId = data?.packageParam?.outOrderId.split("_");
    outOrderId.shift();
    outOrderId = outOrderId.join("_");
  } else {
    outOrderId = data?.packageParam?.outOrderId;
  }

  return (
    <Box
      sx={{
        backgroundColor: "grey.200",
        minHeight: "100vh",
        px: { xs: 0, md: 10 },
        pb: 1,
        mt: "144px",
      }}
    >
      {/* <Toolbar sx={{ height: "144px" }} /> */}
      <CustomInput />
      {isLoading && <></>}
      {data?.success === "false" && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{
            backgroundColor: "white",
            borderRadius: "8px",
            p: "18px 22px",
            height: "50rem",
          }}
        >
          <Box component="img" src="/tracking/noService.png" />
          <Typography variant="h1" color="disabled">
            {t("Tracking.noData")}
          </Typography>
          <Typography variant="subtitle1" color="disabled">
            {t("Tracking.noDataDesc")}
          </Typography>
        </Box>
      )}
      {data && data?.success === "true" && (
        <Grid
          container
          spacing={2.5}
          direction={{ lg: "row", xs: "column" }}
          height="100%"
          wrap="nowrap"
        >
          <Grid item xs={4} xl={4}>
            <Stack
              sx={{
                backgroundColor: "white",
                borderRadius: "8px",
                p: "18px 22px",
              }}
            >
              <Box
                display="flex"
                gap="15px"
                sx={{
                  borderBottom: "1px solid",
                  borderColor: "grey.100",
                  pb: 3,
                }}
              >
                <Box
                  component="img"
                  src="/tracking/packageIcon.png"
                  alt="packageIcon"
                  width={52}
                  height={52}
                  mt="8px"
                />
                <Stack>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="h6">
                      {data?.packageParam?.trackingNumber}
                    </Typography>
                    <CopyButton
                      orderNumber={data?.packageParam?.trackingNumber}
                    />
                  </Box>
                  <StatusWrapper
                    status={
                      isStatusError
                        ? t("Tracking.deliveryFailure")
                        : noTracking
                        ? t("Tracking.noStatus")
                        : isPudoOverdue
                        ? t("Tracking.exceeded")
                        : t("Tracking.inTransit")
                    }
                    isFailed={isStatusError || isPudoOverdue}
                    noTracking={noTracking}
                  />
                </Stack>
              </Box>
              <Stack sx={{ mt: "5px", minWidth: "317px" }} gap="6px">
                {(!isMiraOrAE(data) || (isMiraOrAE(data) && miraId)) &&
                  !isMiraOrAECB(data) && (
                    <Box display="flex" gap={1} alignItems="center">
                      <Typography variant="caption">
                        {t("Tracking.orderNumber")}:
                      </Typography>
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography variant="subtitle2">
                          {data?.packageParam?.trackingNumber?.startsWith("ICP")
                            ? data?.packageParam?.trackingNumber.replace(
                                /001$/,
                                ""
                              )
                            : isMiraOrAE(data)
                            ? miraId
                            : outOrderId}
                        </Typography>
                        <CopyButton orderNumber={outOrderId} />
                      </Box>
                    </Box>
                  )}
                <CaptionWrapper
                  caption={t("Tracking.destination")}
                  subtitle={data?.packageParam?.toCity}
                />
                <CaptionWrapper
                  caption={t("Tracking.zipcode")}
                  subtitle={data?.packageParam?.toZipcode}
                />
                {showWeight() && (
                  <CaptionWrapper
                    caption={t("Tracking.weight")}
                    subtitle={`${
                      data?.packageParam?.dimWeight?.weight / 1000
                    } kg`}
                  />
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={8} xl={8} height="100%">
            {data?.statuses?.length > 0 ? (
              <Stack
                gap={1}
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                  p: "18px 22px",
                  height: "fit-content",
                }}
              >
                <TimelineComponent data={data} />
              </Stack>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                sx={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                  p: "18px 22px",
                  height: "50rem",
                }}
              >
                <Box component="img" src="/tracking/noStatus.png" mb={2} />
                <Typography variant="h1" color="disabled">
                  {t("Tracking.noStatus")}
                </Typography>
                <Typography variant="subtitle1" color="disabled">
                  {t("Tracking.noTrajectoryDesc")}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
