import Banner from "../../components/Banner";
import ContactSection from "../../components/ContactSection";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Cards from "./components/Cards";

export default function Technology() {
  return (
    <>
      <Header />
      <Banner />
      <Cards />
      <ContactSection />
      <Footer />
    </>
  );
}
