import React from "react";
import TweenOne from "rc-tween-one";
import ScrollOverPack from "rc-scroll-anim/lib/ScrollOverPack";
import QueueAnim from "rc-queue-anim";
import { EnvironmentTwoTone } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Col, Divider, Row } from "antd";
import bases from "../Bases.js";

const mid = Math.floor(bases.length / 2);
const bases1 = bases.slice(0, mid);
const bases2 = bases.slice(mid);

export default function Hubs() {
  const [t, i18n] = useTranslation("global");

  return (
    <ScrollOverPack id="hubs" className="content-wrapper">
      <QueueAnim
        className="text-wrapper left-text"
        key="text"
        duration={450}
        type="bottom"
        leaveReverse
      >
        <h1 className="title-hubs" key="h1">
          {t("Hubs.title-hubs")}
        </h1>
        <div key="hubs">
          <ul id="lista">
            <li>{t("Hubs.cross-docking")}</li> |<li>{t("Hubs.micro-cons")}</li>
            <br /> |<li>{t("Hubs.infraestr")}</li>
          </ul>
        </div>
        <div key="points">
          <Row gutter={{ xs: 16, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <ul id="points-map">
                {bases1.map((location, index) => (
                  <li key={index}>
                    <EnvironmentTwoTone /> {location.name}
                  </li>
                ))}
              </ul>
            </Col>
            <Col className="gutter-row" span={12}>
              <ul id="points-map">
                {bases2.map((location, index) => (
                  <li key={index}>
                    <EnvironmentTwoTone /> {location.name}
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </div>
      </QueueAnim>
      <TweenOne
        key="image"
        className="image2 image-wrapper"
        animation={{ x: 0, opacity: 1, ease: "easeOutQuad" }}
        style={{ transform: "translateX(100px)", opacity: 0 }}
      />
    </ScrollOverPack>
  );
}
