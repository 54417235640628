import { Box, Typography } from "@mui/material";

export default function CaptionWrapper({ caption, subtitle }) {
  return (
    <>
      <Box display="flex" gap={1} alignItems="center">
        <Typography variant="caption">{caption}</Typography>
        <Typography variant="subtitle2">{subtitle}</Typography>
      </Box>
    </>
  );
}
